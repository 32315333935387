<template>
  <div>
    <b-form class="mb-2" inline>

      <div class="w-75 pr-1">
        <b-form-input @blur="search"
                      v-model="filter.text"
                      class="w-50"
                      :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('ROUTE')}) | capitalize"
                      id="search-input"></b-form-input>

        <company-single-selector undefined-means="FILTER_OPTIONS_ALL_LABEL" @input="search" class="d-inline-block w-25 pl-1"
                                 value-type="id" v-model="filter.companyId"></company-single-selector>

        <turn-single-selector undefined-means="FILTER_OPTIONS_ALL_LABEL" @input="search"
                              class="d-inline-block pl-1 w-25" value-type="id"
                              v-model="filter.turnId"></turn-single-selector>
      </div>

      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-25" id="status-select">
        <b-form-select-option :value="undefined">{{
            $t('FILTER_ALL_LABEL', {
              catalog: $t('ROUTE'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="true">{{
            $t('FILTER_ACTIVE_LABEL', {
              catalog: $t('ROUTE'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
        <b-form-select-option :value="false">{{
            $t('FILTER_INACTIVE_LABEL', {
              catalog: $t('ROUTE'),
              gender: 'o'
            })  | capitalize
          }}
        </b-form-select-option>
      </b-form-select>
    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter} from "@/mixins";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import TurnSingleSelector from "@/turns/TurnSingleSelector";

export default {
  name: "RouteListFilter",
  components: {TurnSingleSelector, CompanySingleSelector},
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if (this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
